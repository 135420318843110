import React, { useEffect, useState } from "react";
import { useCookieState } from "ahooks";
import { PublicClientApplication } from "@azure/msal-browser";
import { MAX_COOKIE_AGE } from "../../constants";
import { Alert, Flex, Spin } from "antd";
import { microsoftSignIn } from "../../../actions";
import { Button } from "react-bootstrap";

const MicrosoftLogin = ({}) => {
  const [msalInstance, setMsalInstance] = useState(null);
  const [account, setAccount] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const setToken = useCookieState("escs")[1];
  const [shouldSignedIn, setShouldSignedIn] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const setTokenExp = useCookieState("exp")[1];
  const [loginFailed, setLoginFailed] = useState(null);
  // Configuration for MSAL
  const clientId = new URLSearchParams(window.location.search).get("client-id");
  const tenantId = new URLSearchParams(window.location.search).get("tenant-id");
  const msalConfig = {
    auth: {
      clientId,
      authority: `https://login.microsoftonline.com/${tenantId}/`, // For single-tenant
      redirectUri:
        window.location.origin +
        `/accounts/microsoft/login/?client-id=${clientId}&tenant-id=${tenantId}`,
      protocolMode: "OIDC",
      prompt: "select_account",
    },
    cache: {
      cacheLocation: "localStorage", // or 'sessionStorage'
      storeAuthStateInCookie: false, // Set to true for IE11 or Edge
    },
  };

  const loginRequest = {
    scopes: ["openid", "profile", "email", "User.Read"],
    protocolMode: "OIDC",
    prompt: "select_account",
  };

  useEffect(() => {
    const instance = new PublicClientApplication(msalConfig);

    // **Initialize the instance**
    const initializeMsal = async () => {
      try {
        await instance.initialize();

        // Handle redirects after login
        const response = await instance.handleRedirectPromise();

        if (response) {
          const account = response.account;
          instance.setActiveAccount(account);
          setAccount(account);
          acquireToken(instance, account);
        } else {
          const currentAccounts = instance.getAllAccounts();
          if (currentAccounts.length === 1) {
            instance.setActiveAccount(currentAccounts[0]);
            setAccount(currentAccounts[0]);
            acquireToken(instance, currentAccounts[0]);
          } else {
            setShouldSignedIn(true);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    initializeMsal();
    setMsalInstance(instance);
  }, []);

  useEffect(() => {
    if (accessToken) callApi();
  }, [accessToken]);

  useEffect(() => {
    if (msalInstance && !account && shouldSignedIn) {
      login();
    }
  }, [msalInstance, account, shouldSignedIn]);

  useEffect(() => {
    if (isSignedIn) {
      window.location.href = "/";
    }
  }, [isSignedIn]);

  const login = () => {
    if (msalInstance) {
      msalInstance.loginRedirect(loginRequest);
    } else {
      console.error("MSAL instance not initialized");
    }
  };

  const logout = () => {
    if (msalInstance) {
      msalInstance.logoutRedirect();
    } else {
      console.error("MSAL instance not initialized");
    }
  };

  const acquireToken = async (instance, account) => {
    const request = {
      ...loginRequest,
      account: account,
    };

    try {
      const response = await instance.acquireTokenSilent(request);
      // const response = await instance.acquireTokenRedirect(request);
      console.log(response.accessToken);
      setAccessToken(response.accessToken);
    } catch (error) {
      if (error.name === "InteractionRequiredAuthError") {
        instance.acquireTokenRedirect(request);
      } else {
        console.error(error);
      }
    }
  };

  const callApi = () => {
    // Example API call to your backend
    if (accessToken) {
      const data = { token: accessToken };
      microsoftSignIn(
        data,
        async (data) => {
          console.log("API Response:", data);
          const exp = new Date(+new Date() + MAX_COOKIE_AGE);
          await setToken(data.token, {
            expires: (() => exp)(),
          });
          await setTokenExp(exp);
          await setIsSignedIn(true);
        },
        () => {
          setLoginFailed(true);
        }
      );
    } else {
      console.error("Access token is not available");
    }
  };

  return (
    <div className="m-5">
      <Flex gap="middle" vertical>
        {loginFailed ? (
          <div>
            <Alert
              type="warning"
              message="Opps..."
              description="We couldn't recognise your email/account as an existing user. If you are a student, please ask your teacher to add your email to a course or classroom. If you are a teacher, please contact support@curaeducation.com to have your account added."
            />
          </div>
        ) : (
          <Spin spinning={true} delay={500} size="large">
            <Alert
              type="info"
              message="Please wait"
              description="Logging you in with Microsoft Single Sign-On......"
            />
          </Spin>
        )}
        <Button
          variant="link"
          className="mx-auto"
          onClick={() => {
            localStorage.clear();
            sessionStorage.clear();
            window.location.href = "/";
          }}
        >
          <u>Back to login</u>
        </Button>
      </Flex>
    </div>
  );
};

export default MicrosoftLogin;
